.property-nav {
  @extend %paddington;
  @extend %pko-clearfix;
  $root: &;

  top: 0;
  color: $color-blue;
  border-bottom: none;
  @include pko-on-event() {
    color: $color-gray-main;
  }

  @include pko-z-index('sitenav');
  position: relative;

  .icon-home {
    @include pko-font-size(32);

    &.active:hover {
      color: $color-gray-third;
    }
  }

  .icon-home.active,
  .icon-home:hover {
    color: $color-blue;
  }

  &__link {
    line-height: 40px;
    @include pko-text-size-3-way(15, 13, 15);

    &--fll {
      float: left;
    }
    &--flr {
      float: right;
    }
    &--drop {
      width: 230px;
      text-align: right;
    }
  }


}
