.property-results {
  @include standard-wrapper;
  @extend %paddington;
  position: relative;
  margin-top: 20px;

  .filters-wrapper-simple {
    padding-left: 0;

    .filter--inline {
      @include breakpoint($brake-sm) {
        float: right;
        margin-right: -20px;
        padding-right: 0;
        position: relative;
        top: -5px;
        height: 20px;
      }

      @include breakpoint($brake-md) {
        position: static;
      }
    }

    .filter--label {
      @include pko-text-size-3-way(17, 18, 20);

      @include breakpoint($brake-sm) {
        .touch & {
          position: relative;
          top: -15px;
          display: inline;
          padding-right: 10px;
        }
      }

      @include breakpoint($brake-md) {
        position: static;
      }
    }

    .select2-selection--single {
      @include breakpoint($brake-sm) {
        padding-left: 5px;
      }
    }

    .select-mobile-wrapper {
      display: inline-block;
    }
  }

  &__wrap {
    @extend %break-from-paddington;
  }

  &__no-results {
    @extend %paddington;
  }

  &__title {
    margin: 30px 0 0;
    @include pko-text-size-3-way(20, 24, 24);
  }

  &__item {
    @include property-box;
  }
}
