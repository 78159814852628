.property-form {
  &__subject-field {
    margin: 0;
    width: 100%;
    border: 1px solid $color-gray-third;
    border-radius: 3px;
    -webkit-appearance: none;
    background-color: $color-gray-disabled;
    color: $text-color;
    padding: 10px 15px;
    height: auto;
    min-height: 35px;

    &-label {
      @include pko-font-size($font-size-t1-mobile);
      @include breakpoint($brake-sm) {
        @include pko-font-size($font-size-t1);
      }
      display: inline-block;
      padding: 0 0 6px;
    }
  }

  .error {
    border-color: $color-error-border;
    background: $color-pink;
  }

  &__error {
    border-color: $color-error-border;
    background: $color-pink;
  }
}
