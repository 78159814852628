.site-content-property {
  margin-top: 40px;

  &__main {
    position: relative;

    .text {
      h3 {
        margin-bottom: 20px;

        @include breakpoint($brake-sm) {
          margin-bottom: 30px;
        }

        @include breakpoint($brake-md) {
          margin-bottom: 20px;
        }
      }

      a {
        word-wrap: break-word;
        -ms-word-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-all;
        max-width: 300px;
        @include breakpoint($brake-sm) {
          max-width: none;
        }
      }
    }
  }

  &__module {
    padding-bottom: 0;
    margin-bottom: 25px;
  }

  &__additional {
    @include breakpoint($brake-sm) {
      float: right;
    }
  }

  &__second {
    margin-top: 25px;
    position: relative;

    .shrinked {
      overflow: hidden;
    }

    .text {
      max-width: 100%;

      h3 {
        margin-bottom: 20px;

        @include breakpoint($brake-sm) {
          margin-bottom: 30px;
        }

        @include breakpoint($brake-md) {
          margin-bottom: 20px;
        }
      }

      a {
        word-wrap: break-word;
        -ms-word-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-all;
        width: 100%;
        display: inline-block;
        //@include breakpoint($brake-sm) {
        //  max-width: none;
        //}
      }
    }
  }

  &__sidebar {
    .form {
      padding-right: 0;

      &__row {
        max-width: 100%;
      }
    }
  }

  &__show-more {
    display: block;
    position: absolute;
    bottom: -10px;
    width: 100%;

    &-gradient {
      height: 100px;
      background: linear-gradient(to bottom, $transparent, $color-white);
    }
    &-link {
      @include pko-font-size(15);
      cursor: pointer;
      display: block;
      width: 100%;
      background: $color-white;
    }
  }

  .PropertySidebarContactForm {
    margin-top: 35px;

    .text {
      h2,
      p {
        margin-bottom: 3px;
      }
    }

    .property-form__subject-field {
      padding: 7px 15px;
    }

    .form__row--radiobox-agreement {
      margin-bottom: 31px;
    }
  }
}
