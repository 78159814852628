.property-keeper {
  &__image {
    width: auto;
    max-width: 100%;
    margin: 0;
  }

  &__h3 {
    @include pko-text-size-3-way(18, 18, 20);
    margin: 10px 0;
  }

  &__p,
  &__email {
    @include pko-text-size-3-way(12, 14, 15);
    margin: 0;
    line-height: 1.2;
  }
}
