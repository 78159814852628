.property-item {
  position: relative;
  @extend %paddington;

  &__title {
    @include pko-text-size-3-way(19, 26, 32);
    margin: 15px 0 5px;
  }

  &__subtitle {
    @include pko-text-size-3-way(19, 21, 24);
  }
}
