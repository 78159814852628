// todo całość pixel perfect, padding i marginy oprócz %paddingtona z ()*()
// todo rozmiary czcionek
// todo pixel perfect

.property-footer {
  @extend %pko-clearfix;
  margin-bottom: 20px;

  &__info {
    @extend %paddington;
    @include span(12);

    @include breakpoint($brake-sm) {
      @include span(6);
    }

    &-list {
      @extend %pko-clearfix;
      @include pko-font-size($font-size-t2-mobile);
      @include pko-line-height($font-size-t2-mobile, 22);

      color: $color-gray-second;
      list-style: none;
      margin: 6px 0 0;
      padding: 20px 0 0 1px;

      @include breakpoint($brake-xs-sm) {
        @include pko-font-size(14);

        &-item {
          float: left;
          margin-right: 20px;
          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }

  &__links {
    @extend %paddington;
    padding-top: 26px;
    @include span(12);

    @include breakpoint($brake-sm) {
      @include span(6);
      float: right;
    }

    &-list {
      @extend %pko-clearfix;
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: 0; // dla inline-block;

      @include breakpoint($brake-sm) {
        text-align: right;
      }

      &-item {
        margin: 0;
        display: block;
        padding: 6px 0 0;

        &:first-child {
          padding: 0;
        }

        @include breakpoint($brake-sm) {
          display: inline-block;

          &,
          &:first-child {
            padding: 0 0 6px 20px;
          }
        }
      }

      &-link {
        @include pko-text-size-3-way(17, 14, 17);
        white-space: nowrap;
      }
    }
  }
}
