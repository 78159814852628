.property-header {
  @extend %paddington;
  @extend %pko-clearfix;
  padding-bottom: 15px;
  position: relative;

  @include breakpoint($brake-sm) {
    padding-bottom: 30px;
    margin-bottom: 30px;
  }

  @include breakpoint($brake-md) {
    padding-bottom: 40px;
    margin-bottom: 40px;
  }

  &__title {
    &-table-wrap {
      float: left;
      width: 65%;
      margin: 120px 0 0;

      @include breakpoint($brake-sm) {
        display: table;
        width: 100%;
        margin: 0;
      }
    }

    &-table-cell {
      display: block;

      @include breakpoint($brake-sm) {
        display: table-cell;
        height: 132px;
        vertical-align: bottom;
      }

      @include breakpoint($brake-md) {
        height: 187px;
        padding-top: 20px;
      }
    }
  }

  &__h1 {
    display: block;
    text-transform: uppercase;
    width: 100%;
    font-weight: bold;
    padding: 0;
    margin: 0;
    @include pko-text-size-3-way(26, 36, 48);

    @include breakpoint($brake-sm) {
      margin-top: 30px;
      width: 75%;
    }

    @include breakpoint($brake-md) {
      margin-top: 60px;
    }
  }

  &__logo {
    @extend %paddington;
    display: inline-block;
    position: relative;
    float: right;
    margin-top: 18px;
    margin-right: -18px;

    @include breakpoint($brake-sm) {
      position: absolute;
      top: 30px;
      right: 0;
      margin-top: 0;
      margin-right: 0;
    }

    @include breakpoint($brake-md) {
      top: 60px;
      right: -2px;
    }
  }
}
