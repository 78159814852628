.property-recommended {
  @include standard-wrapper;
  @extend %paddington;
  @extend %pko-clearfix;
  position: relative;

  &__wrap {
    @extend %break-from-paddington;
    @extend %pko-clearfix;
  }

  &__title {
    margin: 25px 0 0;
    @include pko-text-size-3-way(20, 20, 24);

    @include breakpoint($brake-sm) {
      margin: 40px 0 0;
    }

    @include breakpoint($brake-md) {
      margin: 50px 0 0;
    }
  }

  &__item {
    @include property-box;
  }
}
